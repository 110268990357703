import { safeRedirect } from '@hapstack/common'
import {
  parseFormData,
  SocialLoginButton,
  useForm,
  useSearchParams,
} from '@hapstack/remix'
import { H1, Notification } from '@hapstack/ui'
import type { ActionFunctionArgs, LoaderFunctionArgs } from '@remix-run/node'
import { Link } from '@remix-run/react'
import { posthog } from 'posthog-js'
import { useEffect } from 'react'
import { z } from 'zod'

import { useClientEnvironmentVariables } from '~/hooks/useClientEnvironmentVariables'
import {
  authenticator,
  redirectIfAuthenticated,
} from '~/services/auth/auth.server'
import { providerSchema } from '~/services/auth/providers'
import { setCookieValue } from '~/utils/cookie.server'

import { loginFormSchema, loginProviderEnum } from './formSchema'
import { INTENTS } from './types'

export async function loader({ request }: LoaderFunctionArgs) {
  return await redirectIfAuthenticated(request)
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await parseFormData(request)
  const provider = providerSchema.parse(formData.provider)
  const redirectTo = safeRedirect(formData.redirectTo as string | undefined)

  try {
    return await authenticator.authenticate(provider, request, {
      successRedirect: redirectTo,
      failureRedirect: '/login',
    })
  } catch (e) {
    if (!redirectTo) throw e
    if (e instanceof Response && isRedirect(e)) {
      e.headers.append(
        'Set-Cookie',
        await setCookieValue('redirectTo', { onSuccess: redirectTo })
      )
      return e
    }
    throw e
  }
}

function isRedirect(response: Response) {
  if (response.status < 300 || response.status >= 400) return false
  return response.headers.has('Location')
}

const sourceSchema = z.enum(['invite']).nullish()

export default function LoginRoute() {
  const { getSearchParam } = useSearchParams()
  const source = sourceSchema.parse(getSearchParam('source'))
  const isAcceptingInvite = source === 'invite'
  const { CONSTANTS } = useClientEnvironmentVariables()

  const redirectTo = getSearchParam('redirectTo') || '/'

  const form = useForm({
    intent: INTENTS.Enum.login,
    schema: loginFormSchema,
    defaultValues: { redirectTo },
  })

  const provider = form.watch('provider')

  const googleLoading =
    provider === loginProviderEnum.Enum.google && form.formState.isSubmitting

  const microsoftLoading =
    provider === loginProviderEnum.Enum.microsoft && form.formState.isSubmitting

  // Clear identifying posthog cookie if user is not logged in
  useEffect(() => {
    posthog.reset()
  }, [])

  return (
    <div className="relative space-y-8">
      {isAcceptingInvite ? (
        <Notification
          variant="info"
          className="absolute -top-24"
        >
          You've been invited to join a team. Please sign in before accepting
          the invitation.
        </Notification>
      ) : null}
      <div>
        <H1 className="flex flex-col space-y-0.5 text-pretty text-5xl">
          <span className="w-fit rounded-lg bg-accent px-2 py-1 text-white shadow-md">
            One-click
          </span>
          <span>login and signup</span>
        </H1>
      </div>

      <div className="space-y-4">
        <SocialLoginButton
          provider={loginProviderEnum.Enum.google}
          loading={googleLoading}
          onClick={() => {
            form.setValue('provider', loginProviderEnum.Enum.google)
            form.handleSubmit()
          }}
        />
        <SocialLoginButton
          provider={loginProviderEnum.Enum.microsoft}
          loading={microsoftLoading}
          onClick={() => {
            form.setValue('provider', loginProviderEnum.Enum.microsoft)
            form.handleSubmit()
          }}
        />
        <small className="block text-xs leading-relaxed text-muted-foreground">
          By using {CONSTANTS.APP_NAME}, you agree to our{' '}
          <Link
            to={`${CONSTANTS.MARKETING_SITE_URL}/priavcy`}
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Privacy Policy
          </Link>{' '}
          and{' '}
          <Link
            to={`${CONSTANTS.MARKETING_SITE_URL}/terms`}
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Terms of Service
          </Link>
          .
        </small>
      </div>
    </div>
  )
}
