import { cn } from '@hapstack/common'
import type { IconName } from '@hapstack/ui'
import { Button } from '@hapstack/ui'
import type { ComponentPropsWithRef } from 'react'

type Props = {
  provider: 'google' | 'microsoft'
} & ComponentPropsWithRef<typeof Button>

export const SocialLoginButton = ({ provider, className, ...props }: Props) => {
  return (
    <Button
      className={cn(
        'h-14 w-full bg-none shadow-sm',
        { 'bg-indigo-500 hover:bg-indigo-600': provider === 'google' },
        { 'bg-slate-600 hover:bg-slate-700': provider === 'microsoft' },
        className
      )}
      icon={provider satisfies IconName}
      {...props}
    >
      <span>
        Continue with <span className="capitalize">{provider}</span>
      </span>
    </Button>
  )
}
